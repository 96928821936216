



// --------------------------------Graph--------------------------------------
const dummyData = [
    {
        categoty: 'Ireland',
        value: 201.1
    },
    {
        categoty: 'Germany',
        value: 165.8
    },
    {
        categoty: 'Australia',
        value: 139.9
    },
    {
        categoty: 'UK',
        value: 99
    }
]
const PieConfig = {
    simplePieChart: {
        type: 'PieChart',
        data: dummyData,
        series: [
            {
                type: 'PieSeries',
                labels: {
                    template: { type: 'AxisLabelCircular', disabled: true }
                },
                dataFields: {
                    value: 'value',
                    category: 'category'
                },
                slices: {
                    template: {
                        type: 'Slice',
                        stroke: '#e7e6f0'
                    }
                }
            }
        ]
    },
    PieChart3D: {
        type: 'PieChart3D',
        data: dummyData,
        series: [
            {
                type: 'PieSeries3D',
                labels: {
                    template: { type: 'AxisLabelCircular', disabled: true }
                },
                dataFields: {
                    value: 'value',
                    category: 'categoty'
                },
                slices: {
                    template: {
                        type: 'Slice',
                        stroke: '#e7e6f0'
                    }
                }
            }
        ]
    },
    bubbleDonut: {
        type: 'PieChart',
        data: dummyData,
        series: [
            {
                type: 'PieSeries',
                labels: {
                    template: { type: 'AxisLabelCircular', disabled: true }
                },
                slices: {
                    template: {
                        type: 'Slice',
                        innerCornerRadius: 7,
                        cornerRadius: 10
                    }
                },
                dataFields: {
                    value: 'value',
                    category: 'categoty'
                }
            }
        ],
        // legend: {
        //   type: 'Legend'
        // },
        radius: '60%',
        innerRadius: '30%'
    },
    donut: {
        "type": "PieChart",
        "data": [{
            "category": "Category #1",
            "value": 3599
        }, {
            "category": "Category #2",
            "value": 3869
        }, {
            "category": "Category #3",
            "value": 3700
        }],
        "series": [{
            "type": "PieSeries",
            "dataFields": {
                "value": "value",
                "category": "category"
            }
        }],
        "radius": "60%",
        "innerRadius": "40%"
    },
    donut3d: {
        "type": "PieChart3D",
        "data": [
            {
                "category": "Category #1",
                "value": 3096
            },
            {
                "category": "Category #2",
                "value": 1194
            },
            {
                "category": "Category #3",
                "value": 4476
            }
        ],
        "series": [
            {
                "type": "PieSeries3D",
                "dataFields": {
                    "value": "value",
                    "category": "category"
                }
            }
        ],
        "radius": "60%",
        "innerRadius": "40%"
    },
    donutRounded: {
        "type": "PieChart",
        "data": [
            {
                "category": "Category #1",
                "value": 2206
            },
            {
                "category": "Category #2",
                "value": 2546
            },
            {
                "category": "Category #3",
                "value": 2871
            },
            {
                "category": "Category #4",
                "value": 2008
            },
            {
                "category": "Category #5",
                "value": 3065
            },
            {
                "category": "Category #6",
                "value": 284
            }
        ],
        "series": [
            {
                "type": "PieSeries",
                "slices": {
                    "template": {
                        "type": "Slice",
                        "innerCornerRadius": 7,
                        "cornerRadius": 10
                    }
                },
                "dataFields": {
                    "value": "value",
                    "category": "category"
                }
            }
        ],
        "legend": {
            "type": "Legend"
        },
        "radius": "60%",
        "innerRadius": "30%"
    },
    nestedDonut: {
        "type": "PieChart",
        "data": [
            {
                "category": "Category #1",
                "value1": 3457,
                "value2": 2092
            },
            {
                "category": "Category #2",
                "value1": 3947,
                "value2": 1560
            },
            {
                "category": "Category #3",
                "value1": 4256,
                "value2": 825
            },
            {
                "category": "Category #4",
                "value1": 4289,
                "value2": 2359
            },
            {
                "category": "Category #5",
                "value1": 3262,
                "value2": 2018
            },
            {
                "category": "Category #6",
                "value1": 1715,
                "value2": 705
            },
            {
                "category": "Category #7",
                "value1": 4758,
                "value2": 1227
            },
            {
                "category": "Category #8",
                "value1": 4539,
                "value2": 3026
            }
        ],
        "series": [
            {
                "type": "PieSeries",
                "ticks": {
                    "template": {
                        "type": "PieTick",
                        "disabled": true
                    }
                },
                "slices": {
                    "template": {
                        "type": "Slice",
                        "stroke": "#fff",
                        "strokeWidth": 2,
                        "strokeOpacity": 1,
                        "states": {
                            "hover": {
                                "properties": {
                                    "shiftRadius": 0,
                                    "scale": 0.9
                                }
                            }
                        }
                    }
                },
                "labels": {
                    "template": {
                        "type": "AxisLabelCircular",
                        "disabled": true
                    }
                },
                "dataFields": {
                    "value": "value1",
                    "category": "category"
                }
            },
            {
                "type": "PieSeries",
                "slices": {
                    "template": {
                        "type": "Slice",
                        "stroke": "#fff",
                        "strokeWidth": 2,
                        "strokeOpacity": 1,
                        "states": {
                            "hover": {
                                "properties": {
                                    "shiftRadius": 0,
                                    "scale": 1.1
                                }
                            }
                        }
                    }
                },
                "dataFields": {
                    "value": "value2",
                    "category": "category"
                }
            }
        ],
        "radius": "60%",
        "innerRadius": "30%"
    },
    simpleGauge: {
        "type": "GaugeChart",
        "xAxes": [
            {
                "type": "ValueAxis",
                "axisRanges": {
                    "values": [
                        {
                            "type": "ValueAxisDataItem",
                            "value": 0,
                            "endValue": 50,
                            "axisFill": {
                                "type": "AxisFillCircular",
                                "fill": "#67b7dc",
                                "fillOpacity": 1
                            }
                        },
                        {
                            "type": "ValueAxisDataItem",
                            "value": 50,
                            "endValue": 80,
                            "axisFill": {
                                "type": "AxisFillCircular",
                                "fill": "#6771dc",
                                "fillOpacity": 1
                            }
                        },
                        {
                            "type": "ValueAxisDataItem",
                            "value": 80,
                            "endValue": 100,
                            "axisFill": {
                                "type": "AxisFillCircular",
                                "fill": "#a367dc",
                                "fillOpacity": 1
                            }
                        }
                    ],
                    "template": {
                        "type": "ValueAxisDataItem"
                    }
                },
                "strictMinMax": true,
                "min": 0,
                "max": 100
            }
        ],
        "hands": {
            "values": [
                {
                    "type": "ClockHand",
                    "id": "h1"
                }
            ],
            "template": {
                "type": "ClockHand"
            }
        },
        "innerRadius": -15
    }
}

const ColumnChart = {
    simple: {
        "type": "XYChart",
        "data": [{
            "category": "1",
            "value": 1028
        }, {
            "category": "2",
            "value": 1054
        }, {
            "category": "3",
            "value": 397
        }, {
            "category": "5",
            "value": 1938
        }, {
            "category": "6",
            "value": 3710
        }, {
            "category": "8",
            "value": 3871
        }],
        "xAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "yAxes": [
            {
                "type": "ValueAxis",
                "min": 0,
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryX}\n{valueY}",
                        "tooltipPosition": "pointer"
                    }
                },
                "dataFields": {
                    "valueY": "value",
                    "categoryX": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ]
    },
    mix: {

        "type": "XYChart",
        "data": [{
            "category": "1",
            "value1": 1028,
            "value2": 3252
        }, {
            "category": "2",
            "value1": 1527,
            "value2": 4382
        }, {
            "category": "3",
            "value1": 371,
            "value2": 1249
        }, {
            "category": "4",
            "value1": 3927,
            "value2": 3651
        }, {
            "category": "5",
            "value1": 3058,
            "value2": 1795
        }, {
            "category": "6",
            "value1": 3615,
            "value2": 4193
        }, {
            "category": "7",
            "value1": 4019,
            "value2": 3117
        }, {
            "category": "8",
            "value1": 1134,
            "value2": 1545
        }],
        "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
                "category": "category"
            },
            "renderer": {
                "grid": {
                    "template": {
                        "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "ColumnSeries",
            "name": "Series 1",
            "columns": {
                "template": {
                    "type": "Column",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}"
                }
            },
            "dataFields": {
                "valueY": "value1",
                "categoryX": "category"
            },
            // "sequencedInterpolation": true,
            // "sequencedInterpolationDelay": 100
        }, {
            "type": "ColumnSeries",
            "name": "Series 2",
            "columns": {
                "template": {
                    "type": "Column",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}"
                }
            },
            "dataFields": {
                "valueY": "value2",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }]
    },
    chartWithValueAxes: {
        "type": "XYChart",
        "data": [{
            "category": "1",
            "value1": 86,
            "value2": 2207
        }, {
            "category": "2",
            "value1": 27,
            "value2": 5817
        }, {
            "category": "3",
            "value1": 70,
            "value2": 9450
        }, {
            "category": "4",
            "value1": 12,
            "value2": 1943
        }, {
            "category": "5",
            "value1": 37,
            "value2": 4860
        }, {
            "category": "6",
            "value1": 13,
            "value2": 4102
        }, {
            "category": "7",
            "value1": 78,
            "value2": 9890
        }, {
            "category": "8",
            "value1": 57,
            "value2": 1273
        }],
        "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
                "category": "category"
            },
            "renderer": {
                "grid": {
                    "template": {
                        "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "id": "axis1",
            "title": {
                "type": "Label",
                "text": "Series 1"
            },
            "min": 0,
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }, {
            "type": "ValueAxis",
            "id": "axis2",
            "title": {
                "type": "Label",
                "text": "Series 2"
            },
            "min": 0,
            "renderer": {
                "opposite": true,
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "ColumnSeries",
            "name": "Series 1",
            "columns": {
                "template": {
                    "type": "Column",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}"
                }
            },
            "dataFields": {
                "valueY": "value1",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }, {
            "type": "ColumnSeries",
            "name": "Series 2",
            "columns": {
                "template": {
                    "type": "Column",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}"
                }
            },
            "yAxis": "axis2",
            "dataFields": {
                "valueY": "value2",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }],
        "legend": {
            "type": "Legend"
        }
    },
    simple3D: {
        "type": "XYChart3D",
        "data": [{
            "category": "1",
            "value": 1028
        }, {
            "category": "2",
            "value": 1054
        }, {
            "category": "3",
            "value": 397
        }, {
            "category": "5",
            "value": 1938
        }, {
            "category": "8",
            "value": 3871
        }],
        "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
                "category": "category"
            },
            "renderer": {
                "grid": {
                    "template": {
                        // "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "min": 0,
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "ColumnSeries3D",
            "columns": {
                "template": {
                    "type": "Column3D",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}",
                    "tooltipPosition": "pointer"
                }
            },
            "dataFields": {
                "valueY": "value",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }]
    },
    mix3D: {
        "type": "XYChart3D",
        "data": [{
            "category": "1",
            "value1": 1532,
            "value2": 4934
        }, {
            "category": "2",
            "value1": 811,
            "value2": 4792
        }, {
            "category": "3",
            "value1": 2367,
            "value2": 4168
        }, {
            "category": "4",
            "value1": 421,
            "value2": 3366
        }, {
            "category": "5",
            "value1": 3727,
            "value2": 1488
        }, {
            "category": "6",
            "value1": 4345,
            "value2": 3505
        }, {
            "category": "7",
            "value1": 4723,
            "value2": 1830
        }, {
            "category": "8",
            "value1": 3404,
            "value2": 1021
        }],
        "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
                "category": "category"
            },
            "renderer": {
                "grid": {
                    "template": {
                        "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "ColumnSeries3D",
            "name": "Series 1",
            "columns": {
                "template": {
                    "type": "Column3D",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}"
                }
            },
            "clustered": true,
            "dataFields": {
                "valueY": "value1",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }, {
            "type": "ColumnSeries3D",
            "name": "Series 2",
            "columns": {
                "template": {
                    "type": "Column3D",
                    "strokeOpacity": 0,
                    "tooltipText": "{categoryX}\n{valueY}"
                }
            },
            "clustered": true,
            "dataFields": {
                "valueY": "value2",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }]
    },
    mixXYColumnLine: {
        "type": "XYChart",
        "data": [
            {
                "category": "Category #1",
                "value1": 208,
                "value2": 2265,
                "value3": 3241
            },
            {
                "category": "Category #2",
                "value1": 4382,
                "value2": 873,
                "value3": 769
            },
            {
                "category": "Category #3",
                "value1": 449,
                "value2": 2958,
                "value3": 2420
            },
            {
                "category": "Category #4",
                "value1": 2156,
                "value2": 1501,
                "value3": 3535
            },
            {
                "category": "Category #5",
                "value1": 2350,
                "value2": 4508,
                "value3": 859
            },
            {
                "category": "Category #6",
                "value1": 4929,
                "value2": 1917,
                "value3": 360
            },
            {
                "category": "Category #7",
                "value1": 3980,
                "value2": 4891,
                "value3": 348
            },
            {
                "category": "Category #8",
                "value1": 285,
                "value2": 2473,
                "value3": 3339
            }
        ],
        "xAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "yAxes": [
            {
                "type": "ValueAxis",
                "id": "axis1",
                "min": 0,
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries",
                "name": "Series 1",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryX}\n{valueY}",
                        "tooltipPosition": "pointer"
                    }
                },
                "dataFields": {
                    "valueY": "value1",
                    "categoryX": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            },
            {
                "type": "ColumnSeries",
                "name": "Series 2",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryX}\n{valueY}",
                        "tooltipPosition": "pointer"
                    }
                },
                "dataFields": {
                    "valueY": "value2",
                    "categoryX": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            },
            {
                "type": "LineSeries",
                "name": "Series 3",
                "bullets": {
                    "values": [
                        {
                            "type": "CircleBullet",
                            "tooltipText": "{name}\n{categoryX}: {valueY}"
                        }
                    ],
                    "template": {
                        "type": "Bullet"
                    }
                },
                "dataFields": {
                    "valueY": "value3",
                    "categoryX": "category"
                },
                "strokeWidth": 2,
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ],
        "legend": {
            "type": "Legend"
        }
    },
    twoValueAxesColumnLine: {
        "type": "XYChart",
        "data": [
            {
                "category": "Category #1",
                "value1": 56,
                "value2": 9752
            },
            {
                "category": "Category #2",
                "value1": 12,
                "value2": 3410
            },
            {
                "category": "Category #3",
                "value1": 48,
                "value2": 4157
            },
            {
                "category": "Category #4",
                "value1": 22,
                "value2": 8048
            },
            {
                "category": "Category #5",
                "value1": 39,
                "value2": 7934
            },
            {
                "category": "Category #6",
                "value1": 81,
                "value2": 7018
            },
            {
                "category": "Category #7",
                "value1": 94,
                "value2": 8441
            },
            {
                "category": "Category #8",
                "value1": 68,
                "value2": 1641
            }
        ],
        "xAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "yAxes": [
            {
                "type": "ValueAxis",
                "id": "axis1",
                "title": {
                    "type": "Label",
                    "text": "Series 1"
                },
                "min": 0,
                "max": 100,
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            },
            {
                "type": "ValueAxis",
                "id": "axis2",
                "title": {
                    "type": "Label",
                    "text": "Series 2"
                },
                "min": 0,
                "max": 10000,
                "renderer": {
                    "opposite": true,
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries",
                "name": "Series 1",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryX}\n{valueY}",
                        "tooltipPosition": "pointer"
                    }
                },
                "dataFields": {
                    "valueY": "value1",
                    "categoryX": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            },
            {
                "type": "LineSeries",
                "name": "Series 2",
                "yAxis": "axis2",
                "bullets": {
                    "values": [
                        {
                            "type": "CircleBullet",
                            "tooltipText": "{name}\n{categoryX}: {valueY}"
                        }
                    ],
                    "template": {
                        "type": "Bullet"
                    }
                },
                "dataFields": {
                    "valueY": "value2",
                    "categoryX": "category"
                },
                "strokeWidth": 2,
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ],
        "legend": {
            "type": "Legend"
        }
    }

}

const BarChart = {
    simple: {
        "type": "XYChart",
        "data": [
            {
                "category": "Category #1",
                "value": 3578
            },
            {
                "category": "Category #2",
                "value": 1146
            },
            {
                "category": "Category #3",
                "value": 4451
            },
            {
                "category": "Category #4",
                "value": 4923
            },
            {
                "category": "Category #5",
                "value": 4847
            },
            {
                "category": "Category #6",
                "value": 2443
            },
            {
                "category": "Category #7",
                "value": 4303
            },
            {
                "category": "Category #8",
                "value": 4027
            }
        ],
        "xAxes": [
            {
                "type": "ValueAxis",
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "yAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryY}\n{valueX}",
                        "tooltipPosition": "pointer"
                    }
                },
                "dataFields": {
                    "valueX": "value",
                    "categoryY": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ]
    },
    simple3D: {
        "type": "XYChart3D",
        "data": [
            {
                "category": "Category #1",
                "value": 2806
            },
            {
                "category": "Category #2",
                "value": 992
            },
            {
                "category": "Category #3",
                "value": 3307
            },
            {
                "category": "Category #4",
                "value": 3100
            },
            {
                "category": "Category #5",
                "value": 2896
            },
            {
                "category": "Category #6",
                "value": 2745
            },
            {
                "category": "Category #7",
                "value": 4083
            },
            {
                "category": "Category #8",
                "value": 1982
            }
        ],
        "xAxes": [
            {
                "type": "ValueAxis",
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "yAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries3D",
                "columns": {
                    "template": {
                        "type": "Column3D",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryY}\n{valueX}",
                        "tooltipPosition": "pointer"
                    }
                },
                "dataFields": {
                    "valueX": "value",
                    "categoryY": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ]
    },
    mix: {
        "type": "XYChart",
        "data": [
            {
                "category": "Category #1",
                "value1": 630,
                "value2": 1698
            },
            {
                "category": "Category #2",
                "value1": 3018,
                "value2": 2995
            },
            {
                "category": "Category #3",
                "value1": 2574,
                "value2": 4939
            },
            {
                "category": "Category #4",
                "value1": 2817,
                "value2": 1312
            },
            {
                "category": "Category #5",
                "value1": 1919,
                "value2": 1514
            },
            {
                "category": "Category #6",
                "value1": 878,
                "value2": 4896
            },
            {
                "category": "Category #7",
                "value1": 3534,
                "value2": 3102
            },
            {
                "category": "Category #8",
                "value1": 2701,
                "value2": 3641
            }
        ],
        "xAxes": [
            {
                "type": "ValueAxis",
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "yAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries",
                "name": "Series 1",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryY}\n{valueX}"
                    }
                },
                "dataFields": {
                    "valueX": "value1",
                    "categoryY": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            },
            {
                "type": "ColumnSeries",
                "name": "Series 2",
                "columns": {
                    "template": {
                        "type": "Column",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryY}\n{valueX}"
                    }
                },
                "dataFields": {
                    "valueX": "value2",
                    "categoryY": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ]
    },
    mix3D: {
        "type": "XYChart3D",
        "data": [
            {
                "category": "Category #1",
                "value1": 4140,
                "value2": 401
            },
            {
                "category": "Category #2",
                "value1": 3141,
                "value2": 2866
            },
            {
                "category": "Category #3",
                "value1": 3025,
                "value2": 2352
            },
            {
                "category": "Category #4",
                "value1": 3442,
                "value2": 2585
            },
            {
                "category": "Category #5",
                "value1": 3919,
                "value2": 1734
            },
            {
                "category": "Category #6",
                "value1": 2851,
                "value2": 1326
            },
            {
                "category": "Category #7",
                "value1": 246,
                "value2": 297
            },
            {
                "category": "Category #8",
                "value1": 4889,
                "value2": 4083
            }
        ],
        "xAxes": [
            {
                "type": "ValueAxis",
                "renderer": {
                    "maxLabelPosition": 0.98
                }
            }
        ],
        "yAxes": [
            {
                "type": "CategoryAxis",
                "dataFields": {
                    "category": "category"
                },
                "renderer": {
                    "grid": {
                        "template": {
                            "type": "Grid",
                            "location": 0
                        }
                    },
                    "minGridDistance": 20
                }
            }
        ],
        "series": [
            {
                "type": "ColumnSeries3D",
                "name": "Series 1",
                "columns": {
                    "template": {
                        "type": "Column3D",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryY}\n{valueX}"
                    }
                },
                "clustered": true,
                "dataFields": {
                    "valueX": "value1",
                    "categoryY": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            },
            {
                "type": "ColumnSeries3D",
                "name": "Series 2",
                "columns": {
                    "template": {
                        "type": "Column3D",
                        "strokeOpacity": 0,
                        "tooltipText": "{categoryY}\n{valueX}"
                    }
                },
                "clustered": true,
                "dataFields": {
                    "valueX": "value2",
                    "categoryY": "category"
                },
                "sequencedInterpolation": true,
                "sequencedInterpolationDelay": 100
            }
        ]
    }
}
const LineConfig = {
    simpleLineChart: {
        "hey": 123,
        "type": "XYChart",
        "data": [{
            "category": "Category #1",
            "value": 399
        }, {
            "category": "Category #2",
            "value": 439
        }, {
            "category": "Category #3",
            "value": 309
        }, {
            "category": "Category #4",
            "value": 399
        }, {
            "category": "Category #5",
            "value": 420
        }, {
            "category": "Category #6",
            "value": 429
        }, {
            "category": "Category #7",
            "value": 330
        }, {
            "category": "Category #8",
            "value": 422
        }],
        "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
                "category": "category"
            },
            "renderer": {
                "grid": {
                    "template": {
                        "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "LineSeries",
            "bullets": {
                "values": [{
                    "type": "CircleBullet",
                    "tooltipText": "{categoryX}\n{valueY}"
                }],
                "template": {
                    "type": "Bullet"
                }
            },
            "dataFields": {
                "valueY": "value",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }]
    },
    twoLineChart: {
        "type": "XYChart",
        "data": [{
            "category": "Category #1",
            "value1": 293,
            "value2": 300
        }, {
            "category": "Category #2",
            "value1": 261,
            "value2": 447
        }, {
            "category": "Category #3",
            "value1": 218,
            "value2": 446
        }, {
            "category": "Category #4",
            "value1": 318,
            "value2": 444
        }, {
            "category": "Category #5",
            "value1": 234,
            "value2": 259
        }, {
            "category": "Category #6",
            "value1": 408,
            "value2": 438
        }, {
            "category": "Category #7",
            "value1": 447,
            "value2": 447
        }, {
            "category": "Category #8",
            "value1": 257,
            "value2": 443
        }],
        "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
                "category": "category"
            },
            "renderer": {
                "grid": {
                    "template": {
                        "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "LineSeries",
            "name": "Series 1",
            "bullets": {
                "values": [{
                    "type": "CircleBullet",
                    "tooltipText": "{name}\n{categoryX}: {valueY}"
                }],
                "template": {
                    "type": "Bullet"
                }
            },
            "dataFields": {
                "valueY": "value1",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }, {
            "type": "LineSeries",
            "name": "Series 2",
            "bullets": {
                "values": [{
                    "type": "Bullet",
                    "children": [{
                        "type": "Rectangle",
                        "width": 10,
                        "height": 10,
                        "horizontalCenter": "middle",
                        "verticalCenter": "middle"
                    }],
                    "tooltipText": "{name}\n{categoryX}: {valueY}"
                }],
                "template": {
                    "type": "Bullet"
                }
            },
            "dataFields": {
                "valueY": "value2",
                "categoryX": "category"
            },
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }]
    },
    smoothedDateLineChart: {
        "type": "XYChart",
        "data": [{
            "date": "2021-08-28T08:05:59.696Z",
            "value1": 68,
            "value2": 90
        }, {
            "date": "2021-08-29T08:05:59.696Z",
            "value1": 75,
            "value2": 115
        }, {
            "date": "2021-08-30T08:05:59.696Z",
            "value1": 69,
            "value2": 104
        }, {
            "date": "2021-08-31T08:05:59.696Z",
            "value1": 86,
            "value2": 118
        }, {
            "date": "2021-09-01T08:05:59.696Z",
            "value1": 54,
            "value2": 87
        }, {
            "date": "2021-09-02T08:05:59.696Z",
            "value1": 68,
            "value2": 97
        }, {
            "date": "2021-09-03T08:05:59.696Z",
            "value1": 97,
            "value2": 117
        }, {
            "date": "2021-09-04T08:05:59.696Z",
            "value1": 89,
            "value2": 113
        }, {
            "date": "2021-09-05T08:05:59.696Z",
            "value1": 93,
            "value2": 114
        }, {
            "date": "2021-09-06T08:05:59.696Z",
            "value1": 81,
            "value2": 86
        }, {
            "date": "2021-09-07T08:05:59.696Z",
            "value1": 74,
            "value2": 102
        }],
        "xAxes": [{
            "type": "DateAxis",
            "dataFields": {},
            "renderer": {
                "grid": {
                    "template": {
                        "type": "Grid",
                        "location": 0
                    }
                },
                "minGridDistance": 20
            }
        }],
        "yAxes": [{
            "type": "ValueAxis",
            "renderer": {
                "maxLabelPosition": 0.98
            }
        }],
        "series": [{
            "type": "LineSeries",
            "name": "Series 1",
            "bullets": {
                "values": [{
                    "type": "CircleBullet",
                    "tooltipText": "{name}\n{dateX}: {valueY}"
                }],
                "template": {
                    "type": "Bullet"
                }
            },
            "dataFields": {
                "valueY": "value1",
                "dateX": "date"
            },
            "strokeWidth": 3,
            "tensionX": 0.8,
            "tensionY": 0.9,
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }, {
            "type": "LineSeries",
            "name": "Series 2",
            "bullets": {
                "values": [{
                    "type": "Bullet",
                    "children": [{
                        "type": "Rectangle",
                        "width": 10,
                        "height": 10,
                        "horizontalCenter": "middle",
                        "verticalCenter": "middle"
                    }],
                    "tooltipText": "{name}\n{dateX}: {valueY}"
                }],
                "template": {
                    "type": "Bullet"
                }
            },
            "dataFields": {
                "valueY": "value2",
                "dateX": "date"
            },
            "strokeWidth": 3,
            "tensionX": 0.5,
            "sequencedInterpolation": true,
            "sequencedInterpolationDelay": 100
        }]
    }
}
// -----------------------------Graph End-------------------------------------


const SubTypeofType = {
    column_bar_chart: [
        {
            text: "Simple Columns Chart",
            value: "simple",
            config: ColumnChart.simple,
            imagePath: "column_bar_chart/column/simple.png"
            // svgIconName: "serialChart/column/simple"
        },
        {
            text: "mix",
            value: "Clustered Column Chart",
            config: ColumnChart.mix,
            // svgIconName: "serialChart/column/mix"
            imagePath: "column_bar_chart/column/mix.png"
        },
        {
            text: "Column chart with two value axes",
            value: "chartWithValueAxes",
            config: ColumnChart.chartWithValueAxes,
            imagePath: "column_bar_chart/column/mixXYAxes.png"
        },
        {
            text: "Simple 3D column chart",
            value: "simple3D",
            config: ColumnChart.simple3D,
            imagePath: "column_bar_chart/column/3D.png"
        },
        {
            text: "Clustered 3D column chart",
            value: "mix3D",
            config: ColumnChart.mix3D,
            imagePath: "column_bar_chart/column/3dMix.png"
        },
        {
            text: "Column/line chart",
            value: "mixXYColumnLine",
            config: ColumnChart.mixXYColumnLine,
            imagePath: "column_bar_chart/column/mixXYColumnLine.png"
        },
        {
            text: "Column/line chart with two value axes",
            value: "twoValueAxesColumnLine",
            config: ColumnChart.twoValueAxesColumnLine,
            imagePath: "column_bar_chart/column/twoValueAxesColumnLine.png"
        },
        {
            text: "Simple bar chart",
            value: "simple-bar",
            config: BarChart.simple,
            imagePath: "column_bar_chart/bar/simple.png"
        },
        {
            text: "Clustered bar chart",
            value: "mix-bar",
            config: BarChart.mix,
            imagePath: "column_bar_chart/bar/mix.png"
        },
        {
            text: "Simple 3D bar chart",
            value: "simple3D-bar",
            config: BarChart.simple3D,
            imagePath: "column_bar_chart/bar/3D.png"
        },
        {
            text: "Clustered 3D bar chart",
            value: "mix3D-bar",
            config: BarChart.mix3D,
            imagePath: "column_bar_chart/bar/3dMix.png"
        },
    ],
    pie_chart: [
        {
            text: 'Simple pie chart',
            value: 'simplePieChart',
            config: PieConfig.simplePieChart,
            // svgIconName: "pie/simplePieChart",
            imagePath: "pie_chart/simple.png"
        },
        {
            text: '3D pie chart',
            value: 'PieChart3D',
            config: PieConfig.PieChart3D,
            // svgIconName: "pie/PieChart3D",
            imagePath: "pie_chart/pie-3d-chart.png"

        },
        {
            text: 'Simple donut chart',
            value: 'donut',
            config: PieConfig.donut,
            // svgIconName: "pie/donut",
            imagePath: "pie_chart/simple-donut-chart.png"
        },
        {
            text: '3D donut chart',
            value: 'donut3d',
            config: PieConfig.donut3d,
            imagePath: "pie_chart/donut3d.png"
        },
        {
            text: 'Nested donut chart',
            value: 'nestedDonut',
            config: PieConfig.nestedDonut,
            imagePath: "pie_chart/nestedDonut.png"
        },
        {
            text: 'Donut chart with rounded corners',
            value: 'donutRounded',
            config: PieConfig.donutRounded,
            imagePath: "pie_chart/donutRounded.png"
        },
        // {
        //     text: 'Simple Gauge chart',
        //     value: 'simpleGauge',
        //     config: PieConfig.simpleGauge,
        //     imagePath: "pie_chart/simpleGauge.png"
        // },
    ],
    line_chart: [
        {
            text: 'Simple line chart',
            value: 'simpleLineChart',
            config: LineConfig.simpleLineChart,
            // svgIconName: "pie/simplePieChart",
            imagePath: "column_bar_chart/line/simple-line-chart.png"
        },
        {
            text: 'Two line chart',
            value: 'twoLineChart',
            config: LineConfig.twoLineChart,
            // svgIconName: "pie/simplePieChart",
            imagePath: "column_bar_chart/line/two-lines-chart.png"
        },
        {
            text: 'Smoothed date-based line chart',
            value: 'smoothedDateLineChart',
            config: LineConfig.smoothedDateLineChart,
            // svgIconName: "pie/simplePieChart",
            imagePath: "column_bar_chart/line/smoothed-date-line-chart.png"
        },
    ],
    bubble_chart: []
};

const SubType = {
    number: [
        {
            text: 'Multiple Number',
            value: 'multiple_number',
            imagePath: "multidata",

        },
        // {
        //     text: 'Big Number',
        //     value: 'big_number'
        // },
        {
            text: 'Ultra Big Number',
            value: 'ultra_big_number',
            imagePath: "singledata",
        },
        {
            text: 'Plain Big Number Tile',
            value: 'plain_big_number_tile',
            imagePath: "plainbigno",
        }
    ],
    table: [
        {
            text: 'Advanced',
            value: 'advanced',
            icon1: 'mdi-table-large',
            svgIconName: 'table/advance_table',
        },
       
        {
            text: 'Pivot Table View',
            value: 'pivot_table_view',
            svgIconName: "table/pivot"
        }
    ],
    button: [
        {
            text: 'Download',
            value: 'download',
            imagePath: "downloadme",
            width: "60",
            height: "20",
        },
        {
            text: 'Email',
            value: 'email',
            imagePath: "emailme",
            height: "20",
            width: "60"
        }
    ],
    html: [
        {
            text: 'Text Display',
            value: 'text_display',
            svgIconName: 'text_diaplay_icon'
        }
    ],
    graph: [
        {
            text: 'Column Bar Chart',
            value: 'column_bar_chart',
            elements: SubTypeofType.column_bar_chart,
        },
        {
            text: 'Pie Chart',
            value: 'pie_chart',
            elements: SubTypeofType.pie_chart
        },
        {
            text: 'Line',
            value: 'line_chart',
            elements: SubTypeofType.line_chart
        },
        // {
        //     text: 'Bubble Chart',
        //     value: 'bubble_chart'
        // },
        // {
        //     text: 'Mix XY',
        //     value: 'mixXY'
        // },

    ]
};

// require()

const elementTypes = [
    {
        text: 'Table',
        value: 'table',
        subTypes: SubType.table
    },
    {
        text: 'Graph',
        value: 'graph',
        subTypes: SubType.graph
    },
    {
        text: 'Widget',
        value: 'number',
        subTypes: SubType.number
    },
    {
        text: 'Html',
        value: 'html',
        subTypes: SubType.html
    },
    {
        text: 'Button',
        value: 'button',
        subTypes: SubType.button
    },
];

module.exports = elementTypes