var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",staticStyle:{"overflow-x":"clip"}},[(_vm.allowImport)?[_c('v-autocomplete',{attrs:{"auto-select-first":"","clearable":"","dense":"","outlined":"","rounded":"","filter":_vm.customFilter,"item-text":"name","item-value":"_id","label":"Search Element by Name/Type","items":_vm.$store.state.allElementsForImport},model:{value:(_vm.selectedElemForImport),callback:function ($$v) {_vm.selectedElemForImport=$$v},expression:"selectedElemForImport"}}),(_vm.selectedElemForImport)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary"},on:{"click":_vm.importSelectedElement}},[_vm._v(" import "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-import ")])],1):_vm._e()]:[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"MNBTR",staticStyle:{"font-size":"small","background-color":"#fff","margin-bottom":"10px"},attrs:{"label":"* Display Name","rules":_vm.displayNameRules,"outlined":"","dense":"","value":_vm.selectedElementLocal.name},on:{"change":function($event){return _vm.onUpdateProps({ name: $event })}}})],1),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panelNo),callback:function ($$v) {_vm.panelNo=$$v},expression:"panelNo"}},_vm._l((_vm.elementTypesConf),function(el,idx){return _c('v-expansion-panel',{key:idx,staticStyle:{"border":"1px solid rgb(228 225 225)","margin-bottom":"3px","border-radius":"5px"},attrs:{"expand":""}},[_c('div',{key:el.value + idx},[_c('v-expansion-panel-header',{staticStyle:{"padding-left":"10px","font-size":"14px"},attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" "+_vm._s(el.text))]),_c('v-expansion-panel-content',[_c('div',{class:el.value != 'graph' ? 'elementTypeContainer' : ''},[_vm._l((el.subTypes),function(n,nIdx){return [(
                    el.value == 'graph' && n.elements && n.elements.length
                  )?[_c('ul',{key:n.value + nIdx,staticClass:"elementTypeContainer visualize"},_vm._l((n.elements),function(gp,gpIdx){return _c('li',{key:gp.value + gpIdx,staticClass:"elementSelectionGraph tooltip",class:_vm.elementTypeSelected(el.value, n.value, gp.value)
                          ? 'elementTypeSelected'
                          : '',staticStyle:{"list-style-type":"none"},on:{"click":function($event){return _vm.onUpdateProps({
                          type: el.value,
                          subType: n.value,
                          graphType: gp.value,
                          configuration: gp.config || {}
                        })}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [(gp.svgIconName)?[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""}},'v-btn',attrs,false),on),[_c('Icon',{staticClass:"svgIcons",staticStyle:{"width":"50px"},attrs:{"name":gp.svgIconName}})],1)]:(gp.imagePath)?[_c('img',_vm._g(_vm._b({attrs:{"width":"40","height":"40","src":_vm.getImgUrl(gp.imagePath),"alt":gp.text}},'img',attrs,false),on))]:_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(gp.text))])])],1)}),0)]:[(n.svgIconName || n.imagePath)?_c('div',{key:n.value + nIdx,staticClass:"elementSelection",class:_vm.elementTypeSelected(el.value, n.value)
                        ? 'elementTypeSelected'
                        : '',attrs:{"id":"simpaleElement"},on:{"click":function($event){return _vm.onUpdateProps({
                        type: el.value,
                        subType: n.value
                      })}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(n.svgIconName)?[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""}},'v-btn',attrs,false),on),[_c('Icon',{staticClass:"svgIcons",staticStyle:{"max-width":"40px"},attrs:{"name":n.svgIconName}})],1)]:[_c('Icon',{staticClass:"svgIcons",attrs:{"name":n.imagePath}})]]}}],null,true)},[_c('span',[_vm._v(_vm._s(n.text))])])],1):_vm._e()]]})],2)])],1)])}),1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }