<template>
  <div class="main" style="overflow-x: clip">
    <!-- <v-switch
      style="margin: unset; padding-left: 10px; width: fit-content"
      inset
      label="Import Element"
      v-model="allowImport"
    ></v-switch> -->
    <!-- IF IMPORT FROM CREATED -->
    <template v-if="allowImport">
      <v-autocomplete
        v-model="selectedElemForImport"
        auto-select-first
        clearable
        dense
        outlined
        rounded
        :filter="customFilter"
        item-text="name"
        item-value="_id"
        label="Search Element by Name/Type"
        :items="$store.state.allElementsForImport"
      ></v-autocomplete>
      <v-btn
        v-if="selectedElemForImport"
        color="primary"
        class="ma-2 white--text"
        @click="importSelectedElement"
      >
        import
        <v-icon right dark> mdi-import </v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-form ref="form" lazy-validation >
      <v-text-field
        style="font-size: small; background-color: #fff; margin-bottom: 10px"
        label="* Display Name"
        :rules="displayNameRules"
        outlined
        dense
        class="MNBTR"
        :value="selectedElementLocal.name"
        @change="onUpdateProps({ name: $event })"
      ></v-text-field>
      </v-form>
      <v-expansion-panels multiple v-model="panelNo">
        <v-expansion-panel
          style="
            border: 1px solid rgb(228 225 225);
            margin-bottom: 3px;
            border-radius: 5px;
          "
          expand
          v-for="(el, idx) in elementTypesConf"
          :key="idx"
        >
          <div :key="el.value + idx" style="">
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
              style="padding-left: 10px; font-size: 14px"
            >
              {{ el.text }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div :class="el.value != 'graph' ? 'elementTypeContainer' : ''">
                <template v-for="(n, nIdx) in el.subTypes">
                  <!-- <div :key="n.value + nIdx" class="elementSubType"> -->
                  <template
                    v-if="
                      el.value == 'graph' && n.elements && n.elements.length
                    "
                  >
                    <ul
                      :key="n.value + nIdx"
                      style=""
                      class="elementTypeContainer visualize"
                    >
                      <li
                        style="list-style-type: none"
                        v-for="(gp, gpIdx) in n.elements"
                        :key="gp.value + gpIdx"
                        class="elementSelectionGraph tooltip"
                        :class="
                          elementTypeSelected(el.value, n.value, gp.value)
                            ? 'elementTypeSelected'
                            : ''
                        "
                        @click="
                          onUpdateProps({
                            type: el.value,
                            subType: n.value,
                            graphType: gp.value,
                            configuration: gp.config || {}
                          })
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <template v-if="gp.svgIconName">
                              <v-btn text icon v-on="on" v-bind="attrs">
                                <Icon
                                  style="width: 50px"
                                  :name="gp.svgIconName"
                                  class="svgIcons"
                                />
                              </v-btn>

                              <!-- style="max-width: 30px" -->
                            </template>
                            <template v-else-if="gp.imagePath">
                              <img
                                v-on="on"
                                v-bind="attrs"
                                width="40"
                                height="40"
                                :src="getImgUrl(gp.imagePath)"
                                :alt="gp.text"
                              />
                            </template>
                          </template>
                          <span>{{ gp.text }}</span>
                        </v-tooltip>
                        <!-- <span class="tooltiptext">{{ gp.text }}</span> -->
                      </li>
                    </ul>
                    <!-- <div
                      :key="n.value + nIdx"
                      style=""
                      class="elementTypeContainer visualize"
                    >
                      <template v-for="(gp, gpIdx) in n.elements"> -->
                    <!-- style="width: 100%" -->
                    <!-- <div
                          v-if="gp.svgIconName || gp.imagePath"
                          :key="gp.value + gpIdx"
                          class="elementSelection"
                          :class="
                            elementTypeSelected(el.value, n.value, gp.value)
                              ? 'elementTypeSelected'
                              : ''
                          "
                          @click="
                            onUpdateProps({
                              type: el.value,
                              subType: n.value,
                              graphType: gp.value,
                              configuration: gp.config || {}
                            })
                          "
                        >
                          <template v-if="gp.svgIconName">
                            <Icon
                              :name="gp.svgIconName"
                              class="svgIcons"
                              style="max-width: 30px"
                            />
                      
                          </template>
                          <template v-else-if="gp.imagePath">
                            <img
                              width="30"
                              height="30"
                              :src="getImgUrl(gp.imagePath)"
                              :alt="gp.text"
                            />
                          </template>
                        </div> -->
                    <!-- <div
                          v-else
                          :key="gp.value + gpIdx"
                          class="elementSubType elementSelection"
                          style="height: 100px; width: 50%; display: flex"
                          :class="
                            elementTypeSelected(el.value, n.value, gp.value)
                              ? 'elementTypeSelected'
                              : ''
                          "
                          @click="
                            onUpdateProps({
                              type: el.value,
                              subType: n.value,
                              graphType: gp.value,
                              configuration: gp.config || {}
                            })
                          "
                        >
                          <ChartPreview
                            :chartConfig="gp.config"
                            style="height: 100px"
                          />
                        </div> -->
                    <!-- </template> -->
                    <!-- </div> -->
                  </template>
                  <!-- not an amchart -->
                  <template v-else>
                    <div
                      v-if="n.svgIconName || n.imagePath"
                      :key="n.value + nIdx"
                      class="elementSelection"
                      :class="
                        elementTypeSelected(el.value, n.value)
                          ? 'elementTypeSelected'
                          : ''
                      "
                      id="simpaleElement"
                      @click="
                        onUpdateProps({
                          type: el.value,
                          subType: n.value
                        })
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <template v-if="n.svgIconName">    <!-- this part if for table only -->
                            <!-- {{ n.svgIconName }} -->

                            <v-btn text icon v-bind="attrs" v-on="on">
                              <Icon
                                :name="n.svgIconName"
                                class="svgIcons"
                                style="max-width: 40px"
                              />
                            </v-btn>
                          </template>
                          <!-- <span style="font-size: 9px; font-weight: bolder">{{
                          n.text
                        }}</span> -->

                          <template v-else>
                            <!-- <img
                              :aspect-ratio="16 / 9"
                              :width="n.width || 50"
                              :height="n.height || 50"
                              :src="getImgUrl(n.imagePath)"
                              :alt="n.text"
                              v-bind="attrs"
                              v-on="on"
                            /> -->
                             <Icon
                                :name="n.imagePath"
                                class="svgIcons"
                                style=""
                              />
                          </template>
                        </template>
                        <span>{{ n.text }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <!-- </div> -->
                </template>
              </div>
            </v-expansion-panel-content>
            <!-- </div> -->
          </div>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>


<script>
import elementTypesConf from './Configurations/ElementsAvailble/index'
import { v4 as uuidv4 } from 'uuid'
import {
  mapMutations as mapRootMutations,
  createNamespacedHelpers as cnh
} from 'vuex'

const { mapGetters, mapMutations, mapActions } = cnh('ElmentBuilderStore')
const { mapActions: mapReportBuilderActions} = cnh('ReportBuilder')
export default {
  components: {},
  data() {
    return {
      displayNameRules: [
        (V) => !!V || 'Required',
      ],
      allowImport: false,
      selectedElemForImport: false,
      panelNo: [0], //elementTypesConf.map((el, idx) => idx),
      elementTypesConf
      // ...initialState()
    }
  },
  computed: {
    ...mapGetters(['selectedElement']),
    selectedElementLocal() {
      return this.selectedElement
    }
  },
  methods: {
    ...mapReportBuilderActions(['detachDrillInfo']),
    ...mapMutations(['setSelectedElementsProps','setDisplayNameBoolean']),
    ...mapRootMutations(['openSnackbarWithTime']),
    ...mapActions(['getEcs']),
    elementTypeSelected(type, subType, graphType) {
      if (this.selectedElement.type && this.selectedElement.subType) {
        if (this.selectedElement.type === 'graph') {
          return (
            this.selectedElement.type == type &&
              this.selectedElement.subType == subType,
            this.selectedElement.graphType == graphType
          )
        }
        return (
          this.selectedElement.type == type &&
          this.selectedElement.subType == subType
        )
      }
    },
    generateRandomName() {
      let ll = uuidv4().split('-')
      return ll[ll.length - 1].toUpperCase()
    },
    async importSelectedElement() {
      console.log(this.selectedElemForImport, this.selectedElement._id)
      let importedElem = JSON.parse(
        JSON.stringify(
          this.$store.state.allElementsForImport.find(
            (el) => el._id == this.selectedElemForImport
          ) || {}
        )
      )

      delete importedElem.record
      importedElem.name = importedElem.name + 'imported'
      delete importedElem.drill // delete drill from import and kept from selected
      delete importedElem._id
      let finalData = { ...this.selectedElement, ...importedElem }

      //importedElem._id = this.selectedElement._id
      await this.$store.dispatch(
        'ReportBuilder/updateLocalVersionOfElementsData',
        {
          op: 'update',
          _id: this.selectedElement._id,
          data: finalData,
          elementIdx:
            this.$store.state.ReportBuilder.selectedContainerElementIndex
        },
        { root: true }
      )
      this.openSnackbarWithTime({
        txt: 'Element imported Successfully',
        time: 3500
      })
      this.$store.commit(
        'ElmentBuilderStore/setSelectedElement',
        this.$store.state.ReportBuilder.selectedElementData
      )
      this.allowImport = false
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.subType.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      )
    },
    onUpdateProps(prop, type) {
      prop = JSON.parse(JSON.stringify(prop))
      // if (prop.type == 'graph' && prop.configuration) {
      //   prop.dataSource = {
      //     headers: Object.keys(
      //       (prop.configuration.data && prop.configuration.data[0]) || {}
      //     ).map((el, id) => ({
      //       header: el,
      //       type:
      //         typeof prop.configuration.data[0][el] == 'string'
      //           ? 'Text'
      //           : 'Number',
      //       id
      //     })),
      //     rows: JSON.parse(JSON.stringify(prop.configuration.data))
      //   }
      // }
      if (
        prop.type &&
        prop.type !== 'graph' &&
        this.selectedElement.type !== prop.type
      ) {
        console.log('===>', this.selectedElement.type, prop.type)
        prop.configuration = {}
        delete prop.graphType
      }
      if (
        prop.type &&
        prop.type !== 'table' &&
        this.selectedElement.type !== prop.type
      ){
        console.log("table deleted>>>>>>", prop,  this.selectedElement)
        if(this.selectedElement && this.selectedElement._id && this.selectedElement.drill){
          delete this.selectedElement.drill
          this.detachDrillInfo({
            elementId: this.selectedElement._id, 
            prop: 'isElement'
          })
        }
      }
      Object.keys(prop).forEach((key) => {
        if (key == 'dataSource') {
          console.log(prop[key])
        }
        if (key == 'configuration' && prop.type == 'graph') {
          prop[key].data = []
        }
        this.setSelectedElementsProps({ prop: key, value: prop[key] })
        this.$store.commit('ReportBuilder/setLocalVersionOfElementsData', {
          prop: key,
          data: prop[key],
          _id: this.selectedElement._id
        })
      })

      this.$store.commit('ReportBuilder/updateElementInContainer', {
        element: this.selectedElement,
        selectedContainerIndex:
          this.$store.state.ReportBuilder.selectedContainerIValue,
        selectedElementIndex:
          this.$store.state.ReportBuilder.selectedContainerElementIndex
      })
      if (this.selectedElement.type && this.selectedElement.subType) {
        // console.log(`[this.$parent]: `, this.$parent)
        this.$parent.setPanelSettingOptions()
      }
      // if (
      //   this.selectedElement.type &&
      //   this.selectedElement.subType &&
      //   this.selectedElement.name
      // ) {
      //   this.$emit('setselectedSettingVal', 'Data')
      // }
      this.$forceUpdate()
    },
    getImgUrl: function (imagePath) {
      try {
        return require('@/assets/previewImages/' + imagePath)
      } catch (error) {
        return false
        return require('@/assets/previewImages/default_img.png')
      }
    }
  },
  created() {
    this.getEcs()
    if (this.selectedElement && this.selectedElement.type) {
      let index = elementTypesConf.findIndex(
        (el, idx) => el.value == this.selectedElement.type
      )
      this.panelNo = [index]
    }
    // eventBus.$on('checkDisplayName', () => {
    //   try {
    //     // console.log(">>>>>>>>>>>>>>>.",this.$refs.form)
    //     if (this.$refs.form && !this.$refs.form.validate()) {
    //       // this.setDisplayNameBoolean(false)
    //       // return
    //     }
    //     // this.setDisplayNameBoolean(true)
    //     // this.$refs.form.resetValidation()
    //   } catch (error) {
    //     console.log(error)
    //   }
    // });
  },
}
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 10px 10px;
  flex: 1 1 auto;
  max-width: 100%;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 5px;
}
.v-expansion-panel-header {
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 35px;
  outline: none;
  padding: 7px 11px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.v-expansion-panel::before {
  border-radius: inherit;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
}

.v-expansion-panel:not(:first-child)::after {
  /* border-top: thin solid; */
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s border-color cubic-bezier(0.4, 0, 0.2, 1),
    0.2s opacity cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: border-color, opacity;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1),
    cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0s, 0s;
}
/* .v-label {
  font-size: small;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
} */
/* .v-input--switch__track {
  border-radius: 8px;
  width: 30px;
  height: 8px;
  left: 2px;
  position: absolute;
  opacity: 0.6;
  right: 2px;
  top: calc(50% - 7px);
}
.v-input--switch__thumb {
  border-radius: 50%;
  top: calc(50% - 10px);
  height: 15px;
  position: relative;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
} */

.MNBTR fieldset {
  border-collapse: collapse;
  border-color: lightgrey !important;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.3s;
  transition-property: color, border-width;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
/* .v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 20px;
} */
.v-text-field {
  font-size: small;
}
/* .v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: unset;
} */
</style>
<style scoped>
.svgIcons {
  display: block;
  margin: auto;
  /* margin-top: 0.5rem; */
}
.elementTypeContainer {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, 50px); */
  grid-gap: 10px;
  flex-wrap: wrap;
  /* padding: 10px; */
}
.elementSubType {
  height: 30px;
}
.elementSelection {
  cursor: pointer;
  padding-top: 2px;
  /* transition: background-color 0.5s; */
  transition: transform 0.2s; /* Animation */
}
.elementSelection:hover {
  background-color: white;
  border-radius: 5px;
  border-radius: 1px dashed;
  z-index: 134;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  /* transform: scale(1.5);
  margin-left: 15px; */
}
.elementSelectionGraph {
  cursor: pointer;
  padding: 2px;
  border: 1px solid #d2e1f3;
  border-radius: 5px;
  margin-bottom: 5px;
  /* transition: background-color 0.5s; */
  /* Animation */
}
.elementSelectionGraph:hover {
  background-color: white;
  border-radius: 5px;
  border-radius: 1px dashed;
  z-index: 134;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* transform: scale(1); */
  /* margin-left: 1px;
  margin-right: 1px; */
  /* padding:2px; */
}
.elementTypeSelected {
  background-color: rgb(247 248 242);
  border-radius: 5px;
  border: 1px solid #2a51e8;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
/* .tooltip { */
/* position: relative; */
/* display: inline-block; */
/* border-bottom: 1px dotted black; */
/* } */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 50px;
  background-color: rgb(119, 117, 117);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* padding: 1px 0; */
  font-size: 7px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.v-text-field{
  width: 800px
}
</style>
